import React from 'react';
import { BlogList } from 'patterns/BlogList';
import { Masthead } from 'patterns/Masthead';
import { Shell } from 'patterns/Shell';

import '../scss/main.scss';

function ArticleListingPage({
    location: {
        pathname,
    },
}) {
    const data = {
        masthead: {
            title: 'Articles about business, money, health, and more',
            description: [
                {
                    spans: [],
                    text: 'A place to share my journey through life and talk about topics I’m interested in.',
                    type: 'paragraph',
                },
            ],
        },
        blog: {
            items: [
                {
                    card_description: 'Reflecting on 2020 – a year that included the most change in my life personally and professionally for a long time.',
                    card_image: {
                        alt: '',
                        dimensions: {
                            height: 1024,
                            width: 768,
                        },
                        url: 'https://images.prismic.io/robsimpson/40f4ac05-edfe-4a78-a70f-7900bb8bdce6_year-in-review.png?auto=compress,format'
                    },
                    card_link_url: {
                        link_type: 'Document',
                        uid: 'articles/2020-year-in-review',
                    },
                    card_title: {
                        text: '2020 year in review',
                    },
                },
                {
                    card_description: 'From the 7th September, I’ll be available for design and development projects on a full-time basis.',
                    card_image: {
                        alt: '',
                        dimensions: {
                            height: 3024,
                            width: 4032,
                        },
                        url: 'https://images.prismic.io/robsimpson/b654c40f-f586-4b69-8fdb-9ff3018fa444_about-office.jpg?auto=compress,format'
                    },
                    card_link_url: {
                        link_type: 'Document',
                        uid: 'articles/full-time-freelance-designer-developer',
                    },
                    card_title: {
                        text: 'Starting a new chapter as a full time freelancer',
                    },
                },
            ],
        },
    };

    return (
        <Shell
            pageDescription="Sharing my insights into the world of freelance web design and development."
            pageSocialImage=""
            pageTitle="Articles"
            pathname={pathname}
        >
            <Masthead
                description={data.masthead.description}
                title={data.masthead.title}
            />
            <BlogList
                items={data.blog.items}
            />
        </Shell>
    );
}

export default ArticleListingPage;
